.power-container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    height: 40vh;
    width: 5vw;
    background: rgba(0, 0, 0, 0.4);
    color: yellow;
    position: fixed;
    bottom: 15vh;
    right: 5vh;
    border-radius: 5vh;
    overflow: hidden; 
    box-sizing: border-box;
  }
  
  .letter {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 5vh;
    cursor: pointer;
    border: 2px solid transparent;
  }
  
  .letter.toggled {
    background-color: green;
  }
  