.highscore-plane {
    position: absolute;
    top: 50%;  /* Position at vertical center */
    right: -100px;  /* Start from off the screen on the right */
    transform: translateY(-50%);  /* Ensure vertical centering */
    width: 40vw;  /* Adjust the width as per your need */
    height: auto;  /* Maintain aspect ratio */
    animation: flyToLeft 5s linear forwards;  /* Animation */
  }
  
  @keyframes flyToLeft {
    from {
      right: -10vw;
    }
    to {
      right: 100%;
    }
  }