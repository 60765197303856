.food-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .food-animation-container.animation-completed .food-animation-image {
    animation: none;
  }
  
  
  .food-animation-image {
    position: absolute;
    bottom: 0;
    animation: animatePizza 4s cubic-bezier(0.42, 0, 0.58, 1) forwards;
    animation-iteration-count: 1;

  }
  
  .food-animation-image img {
    width: 20vh; /* adjust this to control the size of the pizza slices */
    max-height: 30vh;
    height: auto;
  }
  
  @keyframes animatePizza {
    0% {
      transform: translateY(100vh) rotate(0deg);
    }
    50% {
      transform: translateY(0vh) rotate(-20deg); 
    }
    75% {
      transform: translateY(0vh) rotate(20deg); 
    }
    100% {
      transform: translateY(200vh) rotate(0deg);
    }
  }
  
  .pizza-container {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 15vh;
    left: 60%; 
    transform: translateX(-50%);
    gap: 15vh; /* Add the desired spacing between pizza slices */
  }
  
  .pizza-container .food-animation-container {
    animation-delay: var(--animation-delay); /* Add animation delay */
  }
  