.layout-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  height: 80vh;
  
  width: 100%;
  padding-top: 20vh;
}


.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  /* margin: 3vh; */
  height: 20%;
}

.frame-backer {
  background: rgba(255, 255, 255, 0.5);
  padding: 10px;
  border: 2px solid #000;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
}

.leaderboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%;
  width: 40%;
}

.leaderboard {
  height: 100%;
  width: 100%;
}

.buttons-container {
  position: absolute;
  bottom: 0; /* Position at the bottom */
  display: flex;
  justify-content: space-between; /* Push children to the left and right */
  align-items: center;
  width: 100%;
  height: 20%;
}

.button-wrapper {
  margin-bottom: 4vh;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 30%;
  height: 100%; /* or set a specific height */
}

.leaderboardButton {
  height: 20%; /* Adjust based on your need */
  width: 80%;
  object-fit: contain;

}




.leaderboard-backer::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: 'SillyGames';
  src: url('../../public/SillyGames.ttf') format('truetype');
}

body {
  font-family: 'SillyGames', sans-serif;
}

.leaderboard-backer {
  background-image: url('../img/leaderboard-backer-v2.png');
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%; /* Adjust the width as per your need */
  height: 100%; /* Adjust the height as per your need */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  flex-direction: column;
}

.no-highscores {
  width: 100%;
  height: 70%;
  overflow-y: auto;
  font-size: 7vh;
  opacity: 0.7;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.players-container {
  width: 100%;
  height: 80%;
  overflow-y: auto;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.players-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Edge */
}

.leaderboard-title {
  width: 60%; /* Increase this value to make the image larger */
  height: 10%;
  margin: 2vh 0; /* Add margin to the top and bottom to give it some space */
  align-self: center; /* Center the image */
}


