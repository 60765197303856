.splash-screen {
  top: 20vh;
  position: absolute;

  height: 80vh;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
}

.tutorial-splash-screen {
  top: 20vh;
  position: absolute;

  height: 80vh;
  width: 100vw;
  overflow: hidden;
  z-index: 1;
}

@keyframes simplePulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.tutorialText {
  position: absolute;
  top: 3vh;
  left: 0;
  width: 30vw; 
  animation: simplePulseAnimation 1s ease-in-out infinite;
  height: auto;
}

.tutorialTitle {
  position: absolute;
  top: 0;

  left: 5vh;
}

.splashGifContainer {
  margin-bottom: 5vh;
  margin-left: 40vh;
  position: absolute;
}


.splashContent {
  display: flex;
  z-index: -1;
  
  align-items: center;
  justify-content: center;
  height: 60vh; /* Adjust the height value if needed */
}
@keyframes zoomSpin {
  0% {
    transform: scale(0) rotate(0deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(360deg);
    opacity: 1;
  }
}

@keyframes simplePulseAnimation {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.tutorial-splashLogo {
  width: 40vw;
  margin-top: 5vh;
  /* display: none; */
  z-index: 1;
  animation: simplePulseAnimation ease-in-out 1s infinite;
  /* animation: zoomSpin 1s ease-out forwards,
simplePulseAnimation 2s ease-in-out 1s infinite; */
  /* scaleDown 1s ease-in-out forwards 5s;  */
}

.tutorial-splashLogoScaleUp {
  width: 45vw;
  margin-top: 20vh;
  /* display: none; */
  z-index: 1;
  animation: scaleUp 1s ease-in-out forwards;
}

.tutorial-splashLogoScaleDown {
  width: 45vw;
  margin-top: 20vh;
  /* display: none; */
  z-index: 1;
  animation: scaleDown 1s ease-in-out forwards;
}


@keyframes scaleDown {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}


.scaleUpAnim {
  animation: scaleUp 1s ease-in-out forwards 5s;
} 

.splashLogo {
  width: 40vw;
  margin-top: 15vh;
  position: absolute;
  z-index: 1;
  /* animation: simplePulseAnimation 2s ease-in-out 1s 5; */
}



@keyframes scaleUp {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}



.splashButtons {
  display: flex;
  justify-content: center;

  gap: 3vw;
}

.splashButton {
  width: 25vw; 
  height: auto;
  transition: transform 0.2s ease-in-out;
}

.splashButton:active {
  transform: scale(0.9);
}

.splashButton:hover {
  transform: scale(1.1);
}

.high-score {
  left: 2vh;
  font-size: 4vw;
  color: white;
  text-shadow: 0.1vw 0.1vw black;
}

.new-high-score {
  font-size: 3.5vw;
  color: gold;
  padding-left: 2vh;
  padding-right: 2vh;
  animation: pulseAnimation 1s infinite;
  text-shadow: 0.1vw 0.1vw black;
}

.animation-container {
  width: 50vw;
}

.animation {
  width: 100%;
  height: auto;
}

.tutorialContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; /* Cover the entire viewport */
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  animation: tutorialPopUpAnimation 1s ease-in-out forwards;
}

.tutorialLabel {
  position: relative;
  animation: simplePulseAnimation 1s linear infinite;
  font-size: 10vh;
  width: 50%;
}

.tutorialBird {
  height: 100%;
  width: 100%;
  display: block;
  z-index: 99;
  scale: 0.8;
  position: absolute;
  bottom: 25vh;
  left: 31vw;
}

.tutorialBirdComplete {
  height: 100%;
  width: 100%;
  z-index: 99;
  position: absolute;
  bottom: 32vh;
  left: 47vw;
}
