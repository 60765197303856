.game-over {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
    top: 20vh;
    overflow: hidden;
    position: absolute;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .game-over-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .win-coins {
    transform: scale(0.9);
    position: absolute;
    right: 3vh;
    bottom: 7.5vh; /* Optionally, set top position if needed */
    height: 100vh;
    width: auto; /* You might need to adjust this as per your requirements */
    color: white; /* White text color */
    z-index: 99999; /* Ensure the log view is on top of other elements */
    overflow: hidden; /* This will hide anything that overflows this container */
  }

  .gameOverGull {
    position: absolute;
    margin-left: 25vh;
    margin-bottom: 15vh;
  }

  .content {
    position: relative;
    display: flex;
    align-items: center;
    height: 30vh;  /* adjust as necessary */
  }
  

  .score-frame {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    padding: 10px 20px;
  }
  
  .score-container {
    position: absolute;
    top: 5vh;
    left: 4vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto;
  }
  
  
  .game-over-buttons {
    display: flex;
    justify-content: center;
    gap: 4vw;
    margin-top: 5vh;
    height: 5vh;  /* adjust as necessary */
  }
  
  .logo {
    width: 20vw;
    z-index: 1;
  }


  .score-wrapper {
    display: flex;
    align-items: center;
  }
  
  .button {
    width: 25vw; 
    height: auto;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .button:active {
    transform: scale(0.9);
  }
  
  .button:hover {
    transform: scale(1.1);
  }
  