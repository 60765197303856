.tutorialModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 90%;
    z-index: 100;  /* Ensure modal is on top of other elements */
    overflow: none; /* In case your content exceeds the height or width, it becomes scrollable */
}

.tutorialModal img {
    width: 100%; /* To ensure the image takes the full width of its container */
    height: auto; /* Keep image aspect ratio */
    max-height: 100%; /* Ensure the image doesn't exceed the container's height */
}
