.game-view {
  height: 80vh;
  width: 100vw;
  top: 20vh;
  overflow: hidden;
  position: relative;
}

#alexa-text {
  margin-bottom: 0; /* adjust as needed */
}

#eat-food-text {
  margin-top: 0;
}

.label-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically aligns the contents in the middle */
  align-items: center; /* Horizontally aligns the contents in the middle */
  font-size: 5vh;
}

.centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.poop-label-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 5vh;
  position: absolute;
  right: 15vw;
  top: 18vh;
  justify-content: 'center'; 
}
.label-text {
  margin: 0;
text-align: center;
}

#poop-bar-container {
  position: absolute;  
  top: 0;  
  right: 0;  
  display: flex;
  flex-direction: row; 
  margin-top: 3vh;
  align-items: center;
  margin-right: 10vh;
}

#energy-bar-container {
  margin-left: 10vh;
  margin-top: 3vh;
  display: flex; 
  align-items: center;
}


.speech-bubble.hide {
  opacity: 0;
}




.speech-bubble-container img {
  width: 15vw; /* Adjust the width based on your needs */
  height: auto;
}

@keyframes pulseAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.pulse {
  animation: pulseAnimation 1s infinite;
}

.speech-bubble-container {
  position: fixed;
  bottom: 5vh;
  left: 35%;
}

.speech-bubble-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.speech-bubble-content img {
  max-width: 100%;
  max-height: 100%;
}

.speech-bubble {
  position: absolute;
  bottom: 0vh;
  left: 50%;
  opacity: 0;
  transform: translateX(-50%);
  background-position: center;
}

.speech-bubble.pop {
  animation: popUpAnimation 0.5s ease-out forwards, popDownAnimation 0.5s 2.5s ease-out forwards;
}

.speech-bubble.pop-up {
  animation: popUpAnimation 0.5s ease-out forwards;
}

.speech-bubble.pop-down {
  animation: popDownAnimation 0.5s ease-out forwards;
}

@keyframes popUpAnimation {
  0% {
    transform: translate(-50%, 50%) scale(0) rotate(45deg);
    opacity: 0;
  }
  60% {
    transform: translate(-50%, 0) scale(1.2) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 0) scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes popDownAnimation {
  0% {
    transform: translate(-50%, 0) scale(1) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, 50%) scale(0) rotate(20deg);
    opacity: 0;
  }
}


@keyframes fallOffScreenAnimation {
  0% {
    transform: translate3d(0, 0, 0) rotate(0deg);
  }
  20% {
    transform: translate3d(-3vw, 30vh, 0) rotate(-10deg);
  }
  40% {
    transform: translate3d(3vw, 60vh, 0) rotate(10deg);
  }

  100% {
    transform: translate3d(3vw, 150vh, 0) rotate(360deg);
  }
}

.distance-container {
  position: absolute;
  top: 2vh;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}
.distance-label {
  font-size: 4em;
  font-weight: bold;
  color: white;
  margin: 0;
  padding: 5px;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.distance-value {
  font-size: 4em;
  font-weight: bold;
  color: orange;
  margin: 0;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  display: flex;
  align-items: center;
  justify-content: center; /* Add justify-content property to center horizontally */
}


.distance-value-highscore {
  font-size: 6em;
  font-weight: bold;
  color: gold;
  margin: 0;
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
  display: flex;
  align-items: center;
  animation: pulseAnimation 1s infinite;
  justify-content: center; /* Add justify-content property to center horizontally */
}





.distance-number {
  padding: 5px;
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
}


.curved-arrow {
  position: absolute;
  top: 50%; /* Adjust according to your layout */
  left: 10%; /* Adjust according to your layout */
  transform: rotate(90deg); /* Rotate the image */
}

.command-text {
  position: absolute;
  top: 60%; /* Adjust according to your layout */
  left: 10%; /* Adjust according to your layout */
  color: white; /* Choose the color of your choice */
  font-size: 2em; /* Adjust the size as per your requirement */
  text-shadow: 2px 2px #000; /* Adds a shadow to make text more readable */
}


@keyframes pulsing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


#poopCentreTimer {
  position: absolute;
  top: 60%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  text-align: center;
}

.timer-content {
  font-size: 30vh;
}

.text-container {
  margin-top: 2vh; /* Adjust the spacing as per your needs */
}

.explosion-text {
  font-size: 10vh; /* Adjust the size as per your needs */
}

.poop-image-container {
  position: absolute;
  bottom: -4vh;
  right: -12vh;
}

.poop-image {
  width: 50vh; /* Adjust the size as per your needs */
  animation: pulseAnimation 1s infinite;
}

@keyframes wiggleAnimation {
  0% {
    transform: translateX(-2vh);
  }
  50% {
    transform: translateX(2vh);
  }
  100% {
    transform: translateX(-2vh);
  }
}
