.power-mode {
    position: fixed; /* Changed from absolute to fixed to cover the entire viewport */
    top: 20vh;
    left: 0;
    right: 0;
    bottom: 0vh;
    display: flex; /*flex */
    align-items: end;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
    color: white; /* White text color */
    z-index: 99999; /* Ensure the view is on top of other elements */
  }
  
  .powerModeImage {
    padding-bottom: 3vh;
    width: 100%;
    object-fit: contain;
    animation: pulsing 0.5s infinite;
    height: 50%;
  }