.parallax-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: absolute;
    z-index: -999;
    
  }
  
  .parallax-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 100%;
    animation: parallaxAnimation 5s infinite linear;
  }

  .parallax-image-fast {
    position: absolute;
    top: 0;
    left: 0;
    width: 500%;
    height: 100%;
    animation: parallaxAnimation 5s infinite linear;
  }
  
  .parallax-image img {
    display: block;
    width: 20%;
    height: 100%;
    float: left;
  }
  
  @keyframes parallaxAnimation {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-60%);
    }
  }
  