.falling-cakes-viewport {
    position: fixed; /* Change from relative to fixed */
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 9999; /* Adjust if needed to ensure it's above other content */
    overflow: hidden;
}

.falling-cakes {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@keyframes falling {
    0% {
      transform: translateY(0) rotate(0);
    }
    100% {
      transform: translateY(140vh) rotate(900deg); /* Move past the bottom of the viewport with rotation */
    }
  }
  
  .cake {
    position: absolute;
    width: 10%; /* Reduce the width; adjust as needed */
    object-fit: contain; /* To maintain aspect ratio */
    animation: none;
  }

  .cake.animate {
    animation: falling 1.5s linear forwards; /* Falling animation only when 'animate' class is present */
  }