.log-view {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
    background-color: rgba(0,0,0,0.7); /* Semi-transparent black background */
    color: white; /* White text color */
    z-index: 100; /* Ensure the log view is on top of other elements */
  }
  
  .log-view p {
    font-size: 1.5em; /* Make the text bigger */
  }