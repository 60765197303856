.pulse-image {
    position: absolute;
    position:"absolute"; 
    top: "22vh";
    width: 20vh;
    opacity: 0;
    background-position: center;
}

.pulse-image.pop {
    animation: popUpAnimationPulse ease-out forwards, popDownAnimationPulse ease-out forwards;
}

.pulse-image.pop-up {
    animation: popUpAnimationPulse 0.5s ease-out forwards;
}

.pulse-image.pop-down {
    animation: popDownAnimationPulse 0.5s ease-out forwards;
}

@keyframes popUpAnimationPulse {
    0% {
      transform: scale(0);
      opacity: 0;
    }
  
    60% {
      transform: scale(1.1);
      opacity: 0.7;
    }
  
    80% {
      transform: scale(0.9);
      opacity: 0.9;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  

@keyframes popDownAnimationPulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0);
        opacity: 0;
    }
}