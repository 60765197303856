.player {
  background-image: url('../img/rounded-rectangle.png');
  margin: 1vh 0; /* Adjust margin as needed */
  width: 80%; /* Increase width as needed */
  height: 16%; /* Increase height as needed */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2vw; /* Adjust padding as needed */
  box-sizing: border-box;
}

.player-rank {

  font-weight: bold;
  font-size: 4vh;
  color: black;
  width: 20% /* Adjust icon width as needed */
}

.player-info {
  flex-grow: 1;
  padding: 0 2vw; /* Add padding around player info */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.player-name {
  text-align: center;
  font-size: 7vh; /* Adjust text size as needed */
  font-weight: bold;
  color: black;
}


.score-label {
  font-size: 1.6vh; /* Adjust text size as needed */
}

.player-date {
  font-size: 4vh; /* Adjust text size as needed */
  font-weight: bold;
}
