.pressable-button-container {
    display: flex; /* Aligns the child divs (buttons) in a row */
    justify-content: space-between; /* Places one button to the left and the other to the right of the container */
    align-items: center; /* Centers the buttons vertically */
    position: fixed; 
    bottom: 1vh; 
    width: 100%; /* Makes the container span the entire width of the viewport */
}

.pressable-button:first-child {
    padding-right: 15vw; /* Moves the left button to the right */
}

.pressable-button:last-child {
    padding-left: 15vw; /* Moves the right button to the left */
}

.pressable-button {
    width: 20vh; 
    height: 20vh; 
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer; /* Moved cursor style here to indicate clickable area */
    outline: none; /* Removes the default focus outline */
    -webkit-tap-highlight-color: transparent; /* Removes the tap highlight on mobile devices */
}

.pressable-button:focus {
    outline: none; /* Ensures no outline appears when the button is focused */
}
