.game-sprite-animation-container {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(0.8); /* Reduce the size to half */
  transform: translate(-40%, 0);
}

.full-screen-gif {
  height: 100%;
  width: 100%;
  position: 'absolute';
  top: 0;
  left: 0;
  width: '100vw';
  height: '100vh';
  object-fit: 'cover';
}

.game-gif.fallOff {
  animation: fallOffScreenAnimation 1s linear forwards;
}

.game-gif.flyBack {
  animation: flyBackAnimation 1s linear
}

.game-gif.flyRight {
  animation: flyOffScreenRight 4s linear forwards
}


.game-gif.flyLeft {
  animation: flyOffScreenLeft 2s linear forwards;
}


.game-gif.flyUp {
  animation: flyUpAnimation 1s cubic-bezier(0.4, 0, 0.6, 1) forwards
}

.game-gif.flyDown {
  animation: flyDownAnimation 1s cubic-bezier(0.4, 0, 0.6, 1) forwards
}
.game-gif.idle {
  /* animation: hoverAnimation 2s infinite alternate; */
}

.game-gif.superIdle {
  animation: hoverNoMoveAnimation 2s infinite alternate;
}

.game-gif {
  width: 25vw;
  height: auto;
  position: absolute;
  margin-top: 16vh;
  margin-left: 25vh;
}


@keyframes flyUpAnimation {
  0% {
    transform: translate(-50%, 100%)
  }
  100% {
    transform: translate(0%, 0%)
  }
}

@keyframes flyOffScreenRight {
  0% {
    transform: translateX(0%)
  }

  100% {
    transform: translateX(300%)
  }
}

@keyframes flyOffScreenLeft {
  0% {
    transform: translateX(300%) scaleX(-1)
  }

  99% {
    transform: translateX(0%) scaleX(-1)
  }
  100% {
    transform: translateX(0%) scaleX(1)
  }
}

@keyframes flyRightAnimation {
  0% {
    transform: translateX(0%) scaleX(1);
  }

  40% {
    transform: translateX(100%) scaleX(1);
  }

  41% {
    transform: translateX(100%) scaleX(-1);
  }

  80% {
    transform: translateX(-100%) scaleX(-1);
  }

  81% {
    transform: translateX(-100%) scaleX(1);
  }

  100% {
    transform: translateX(0%) scaleX(1);
  }
}


@keyframes tutorialPopUpAnimation {
  0% {
    transform: scale(0) translateY(0);
    opacity: 0; /* Start out invisible */
  }
  50% {
    transform: scale(1.2) translateY(-20px); /* Move up a bit and slightly over-scale */
    opacity: 1;
  }
  100% {
    transform: scale(1) translateY(0); /* Move back down and return to normal size */
    opacity: 1;
  }
}


@keyframes flyDownAnimation {
  0% {
    transform: translateY(0) translateX(0); /* Starting position */
  }
  100% {
    transform: translateY(150vh) translateX(50vw); /* Ending position - adjust the translateX value for diagonal movement */
  }
}




  @keyframes flyDownAnimation {
    0% {
      transform: translateY(0) translateX(0); /* Starting position */
    }
    100% {
      transform: translateY(150vh) translateX(50vw); /* Ending position - adjust the translateX value for diagonal movement */
    }
  }

  @keyframes flyBackAnimation {
    0% {
      transform: translateY(150vh) translateX(50vw); /* Starting position - same as the ending position of flyDownAnimation */
    }
    100% {
      transform: translateY(0) translateX(0); /* Ending position - back to the initial position */
    }
  }
  
  @keyframes hoverAnimation {
    0% {
      transform: translateY(0); /* Starting position */
    }
    50% {
      transform: translateY(-5vh); /* Mid-flight position */
    }
    100% {
      transform: translateY(0); /* Ending position */
    }
  }
  
    
  @keyframes hoverNoMoveAnimation {
    0% {
      transform: translateY(0); /* Starting position */
    }
    50% {
      transform: translateY(-5vh); /* Mid-flight position */
    }
    100% {
      transform: translateY(0); /* Ending position */
    }
  }
  